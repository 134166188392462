
import { View } from 'client-website-ts-library/plugins';
import { API, Config, Logger, LogLevel } from 'client-website-ts-library/services';
import { WebsiteLevel } from 'client-website-ts-library/types';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class PrivacyPolicy extends Mixins(View) {
  private businessName: string | null = null;

  mounted() {
    switch (Config.Website.Settings!.WebsiteLevel) {
      case WebsiteLevel.Profile:
        API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then((profile) => {
          this.businessName = profile.Office.FranchiseName;
        });
        break;
      case WebsiteLevel.Office:
        API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
          this.businessName = office.FranchiseName;
        });
        break;
      case WebsiteLevel.Franchise:
        API.Franchises.Get(Config.Website.Settings!.WebsiteId).then((franchise) => {
          this.businessName = franchise.Name;
        });
        break;
      default:
        Logger.Log(LogLevel.Error, 'Invalid Website Level');
        break;
    }
  }
}
